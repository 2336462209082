import { scrollElementTo } from 'widgets/toolbox/scroll';
import { getViewType } from 'widgets/toolbox/viewtype';
import cssLoadChecker from 'widgets/toolbox/cssLoadChecker';
import { timeout } from 'widgets/toolbox/util';
import { TWidget } from 'widgets/Widget';
import { IRefElement } from 'widgets/toolbox/RefElement';

const DEFAULT_DIRECTION = 'horizontal';

const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32
});

/**
 * @param Widget Base widget for extending
 * @returns Carousel class
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class Carousel
     * @augments Widget
     * @classdesc Represents Carousel component with next features:
     * 1. Allow to set carousel direction based on the view type
     * 2. Allow to use pagination for carousel rendered by mustache template
     * 3. Allow to scroll carousel to the next/previous/custom( index can be passed to the method) page index
     * 4. Allow to scroll by on the page click(can be used for carousel with thumbnails)
     * 5. Allow to scroll to custom element's position
     * 6. Allow to scroll to focused element
     * 7. Support mousemove, touchmove, mouseup, mousedown, keydown event so we can use carousel even on touch devices.
     * Also we can control carouse with keyboard
     * 8. Allow to get carousel images
     *
     * <br>Uses as a basis slider from here (ScrollCarousel.js):
     * <br>https://github.com/dimanech/aria-components/tree/master/cartridge1/js/components/carousels/carousel
     * @property {string} data-widget - Widget name `carousel`
     * @property {string} data-elem-prev-button - Previous button element
     * @property {string} data-elem-next-button - Next button element
     * @property {string} data-elem-carousel-track - Carousel inner element
     * @property {string} data-direction - Carousel direction - an object, contains direction per viewport
     * @example <caption>Example of Carousel widget usage</caption>
     * <div
     *     data-widget="carousel"
     *     data-elem-prev-button="elemPrevButton"
     *     data-elem-next-button="elemNextButton"
     *     data-elem-carousel-track="elemCarouselTrack"
     *     data-direction='{
     *         "small": "horizontal",
     *         "medium": "horizontal",
     *         "large": "vertical",
     *         "extraLarge": "vertical"
     *     }'
     * >
     *     <button
     *         class="carousel__ctrl _prev"
     *         data-elem-prev-button
     *         tabindex="-1"
     *         aria-hidden="true"
     *         data-ref="elemPrevButton"
     *         data-event-click="scrollToPrevPage"
     *     >Prev</button>
     *     <div
     *         class="carousel__track"
     *         data-elem-carousel-track
     *         data-ref="elemCarouselTrack"
     *         data-event-scroll="onScroll"
     *         data-event-touchstart="onScroll"
     *         data-event-mousedown.prevent="onMouseDown"
     *         data-event-mouseup="onMouseUp"
     *     >
     *         <isloop items="${slotcontent.content}" var="contentAsset">
     *             <div class="box _single" tabindex="0">
     *                 <isprint value="${contentAsset.custom.body}" encoding="off" />
     *             </div>
     *         </isloop>
     *     </div>
     *     <button
     *         class="carousel__ctrl _next"
     *         data-elem-next-button
     *         tabindex="-1"
     *         aria-hidden="true"
     *         data-ref="elemNextButton"
     *         data-event-click="scrollToNextPage"
     *     >Next</button>
     *     <div class="pagination" data-ref="pagination"></div>
     *     <script type="template/mustache" data-ref="template">
     *         <div class="pagination" data-ref="pagination">
     *             {{${'#'}pagination}}
     *             <button
     *                 class="page"
     *                 data-page="{{page}}"
     *                 tabindex="-1"
     *                 data-event-click.prevent="handlePaginationClick"
     *             >
     *             </button>
     *             {{/pagination}}
     *         </div>
     *     </script>
     * </div>
     */
    class Carousel extends Widget {
        currentPage = 0;

        pagination: Promise<HTMLElement> | undefined;

        private scrollingTimeoutValue = 60;

        private grabbingTimeoutValue = 60;

        private delta = 0;

        private roundingDelta = 1;

        private carouselDirection = DEFAULT_DIRECTION;

        private carouselDimension = 0;

        private initialCoordinate = 0;

        private initialScrollPosition = 0;

        private isScrollStart: boolean | undefined;

        private isScrollEnd: boolean | undefined;

        private mouseMoveDisposable?: (() => void)[] | undefined;

        private mouseLeaveDisposable?: (() => void)[] | undefined;

        private scrollingTimeout: NodeJS.Timeout | undefined;

        private grabbingRemoveTimeout: NodeJS.Timeout | undefined;

        private isCallInNextFrameRequested: boolean | undefined;

        prefs() {
            return {
                elemPrevButton: 'elemPrevButton',
                elemNextButton: 'elemNextButton',
                elemCarouselTrack: 'elemCarouselTrack',
                pagerCurrentClass: 'm-current',
                grabbingClass: 'm-grabbing',
                mouseMoveNavigationClass: 'm-mousemove_navigation',
                direction: {
                    small: 'horizontal',
                    medium: 'horizontal',
                    large: 'horizontal',
                    extraLarge: 'horizontal'
                },
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
         */
        init() {
            super.init();
            // Async loading to not block other widget init
            timeout(() => {
                cssLoadChecker.get().then(() => this.initCarousel());
            }, 0);
        }

        /**
         * @description Initial carousel configuration
         * @listens "viewtype.change"
         */
        initCarousel(): void {
            this.defineCarouselDirection();
            this.eventBus().on('viewtype.change', 'onViewtypeChange');
            this.ev('focusin', this.handleScrollToFocusedItem, this.ref(this.prefs().elemCarouselTrack).get());

            this.onScroll();
            this.updateCarouselState();
            this.initPagination();
            this.setActivePagination();
            this.getCarouselDimension();

            this.ref('self').addClass('m-inited');
        }

        /**
         * @description Update carousel. Used on PDP variation change
         * @returns Carousel instance
         */
        update(): Carousel {
            this.initCarousel();

            return this;
        }

        /**
         * @description Viewtype change event handler. Recalculates carousel dimension and redefines carousel direction.
         */
        onViewtypeChange(): void {
            this.defineCarouselDirection();
            this.getCarouselDimension();
        }

        /**
         * @description Scroll carousel to the next page
         * @listens dom#click
         */
        scrollToNextPage(): void {
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (this.carouselDirection === 'horizontal') {
                elemCarouselTrack?.scrollBy(this.carouselDimension, 0);
            } else {
                elemCarouselTrack?.scrollBy(0, this.carouselDimension);
            }
        }

        /**
         * @description Scroll carousel to the previous page
         * @listens dom#click
         */
        scrollToPrevPage(): void {
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (this.carouselDirection === 'horizontal') {
                elemCarouselTrack?.scrollBy(-this.carouselDimension, 0);
            } else {
                elemCarouselTrack?.scrollBy(0, -this.carouselDimension);
            }
        }

        /**
         * @description Scroll carousel to the page index
         * @param pageIndex Page index scroll to
         * @returns Carousel instance
         */
        scrollToPage(pageIndex: number): Carousel {
            const slideDimension = this.ref(this.prefs().elemCarouselTrack).get()?.firstElementChild?.clientWidth || 0;
            const pageStarPoint = Math.round(slideDimension * pageIndex);

            if (this.carouselDirection === 'horizontal') {
                this.scrollTo(0, pageStarPoint);
            } else {
                this.scrollTo(pageStarPoint, 0);
            }

            return this;
        }

        /**
         * @description Scroll element to point
         * @param top top position
         * @param left left position
         */
        scrollTo(top: number, left: number): void {
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (elemCarouselTrack) {
                scrollElementTo(elemCarouselTrack, top, left);
            }
        }

        /**
         * @description On scroll we update carousel controls state
         */
        onScroll(): void {
            this.updateCarouselMetric();

            if (!this.isCallInNextFrameRequested) {
                window.requestAnimationFrame(this.updateDuringScroll.bind(this));

                this.isCallInNextFrameRequested = true;
            }
        }

        /**
         * @description On carousel scroll end handler
         * @emits Carousel#pagechanged
         */
        onScrollEnd(): void {
            /**
             * @description Event to carousel page change
             * @event Carousel#pagechanged
             */
            this.emit('pagechanged', this.getCurrentPageIndex());
        }

        /**
         * @description Update carousel during scroll
         */
        updateDuringScroll(): void {
            this.updateCarouselState();

            if (this.pagination) {
                this.setActivePagination();
            } else {
                this.initPagination();
            }

            if (this.scrollingTimeout) {
                clearTimeout(this.scrollingTimeout);
            }

            this.scrollingTimeout = setTimeout(() => this.onScrollEnd(), this.scrollingTimeoutValue);

            this.isCallInNextFrameRequested = false;
        }

        /**
         * @description Update carousel state
         */
        updateCarouselState(): void {
            const carousel = this.ref('self');
            const elemPrevButton = this.ref(this.prefs().elemPrevButton);
            const elemNextButton = this.ref(this.prefs().elemNextButton);

            carousel.toggleClass('m-no_scroll', this.hasNoScroll());

            if (this.isScrollStart) {
                carousel.removeClass('m-prev_visible');
                elemPrevButton.attr('disabled', '');
            } else {
                carousel.addClass('m-prev_visible');
                elemPrevButton.attr('disabled', false);
            }

            if (this.isScrollEnd) {
                carousel.removeClass('m-next_visible');
                elemNextButton.attr('disabled', '');
            } else {
                carousel.addClass('m-next_visible');
                elemNextButton.attr('disabled', false);
            }
        }

        /**
         * @description Update carousel metric
         */
        updateCarouselMetric(): void {
            const carouselTrackElem = this.ref(this.prefs().elemCarouselTrack).get();
            const carouselElem = this.ref('self').get();

            const roundingDelta = this.roundingDelta;

            if (carouselTrackElem && carouselElem) {
                if (this.carouselDirection === 'horizontal') {
                    const totalScrollWidth = Math.max(0, carouselTrackElem.scrollLeft) + carouselElem.offsetWidth;

                    // We are comparing with 1 instead of 0 as Chrome has a problem calculating the width with RTL text direction
                    this.isScrollStart = Math.max(0, carouselTrackElem.scrollLeft) <= 1;
                    this.isScrollEnd = (totalScrollWidth + roundingDelta) >= carouselTrackElem.scrollWidth;

                    this.checkTrackLimits(carouselTrackElem, 'horizontal');
                } else {
                    const totalScrollHeight = carouselTrackElem.scrollTop + carouselTrackElem.offsetHeight;

                    this.isScrollStart = Math.max(0, carouselTrackElem.scrollTop) <= 0;
                    this.isScrollEnd = (totalScrollHeight + roundingDelta) >= carouselTrackElem.scrollHeight;
                }
            }
        }

        /**
         * @param carouselTrackElem our carousel track HTML element
         * @param direction whether the carousel is a vertical or horizontal one
         */
        checkTrackLimits(carouselTrackElem: HTMLElement, direction: string) {
            if (direction === 'horizontal') {
                if (carouselTrackElem.scrollLeft < 0) {
                    carouselTrackElem.scrollLeft = 0;
                }

                if (carouselTrackElem.scrollLeft + carouselTrackElem.offsetWidth > carouselTrackElem.scrollWidth) {
                    carouselTrackElem.scrollLeft = carouselTrackElem.scrollWidth;
                }
            }
        }

        /**
         * @description Method to get carousel dimension (width or height) depending on carousel direction
         * @returns width or height of carousel
         */
        getCarouselDimension(): number {
            if (!this.carouselDimension) {
                const carouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

                if (!carouselTrack) {
                    return 0;
                }

                this.carouselDimension = this.carouselDirection === 'horizontal'
                    ? carouselTrack.clientWidth
                    : carouselTrack.clientHeight;
            }

            return this.carouselDimension;
        }

        getCurrentScrollPosition(): number {
            const carouselTrack = this.ref('elemCarouselTrack').get();

            if (!carouselTrack) {
                return 0;
            }

            return this.carouselDirection === 'horizontal' ? carouselTrack.scrollLeft : carouselTrack.scrollTop;
        }

        /**
         * @description set carousel direction property from widget config
         */
        defineCarouselDirection() {
            this.carouselDirection = this.prefs().direction[getViewType()] || DEFAULT_DIRECTION;
        }

        /**
         * @description Check if carousel has no scroll
         * @returns indicated is carousel has no scroll
         */
        hasNoScroll(): boolean {
            return Boolean(this.isScrollStart && this.isScrollEnd);
        }

        // Swipe / Drag
        // =====================================================================

        /**
         * @description Initialize mouse move handling on mouse down
         * @listens dom#mousedown
         * @param el source of event
         * @param event event instance in DOM
         */
        onMouseDown(el: IRefElement, event: MouseEvent): void {
            this.initialCoordinate = this.getCurrentCoordinate(event);
            this.initialScrollPosition = this.getCurrentScrollPosition();

            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (!elemCarouselTrack) {
                return;
            }

            elemCarouselTrack.classList.add(this.prefs().mouseMoveNavigationClass);

            this.mouseMoveDisposable = this.ev<HTMLElement, MouseEvent>('mousemove', this.onMouseMove, elemCarouselTrack);
            this.mouseLeaveDisposable = this.ev<HTMLElement, MouseEvent>('mouseleave', this.onMouseUp, elemCarouselTrack);

            if (this.grabbingRemoveTimeout) {
                clearTimeout(this.grabbingRemoveTimeout);
            }
        }

        /**
         * @description Handle Mouse/Touch move
         * @listens dom#mousemove
         * @listens dom#touchmove
         * @param element HTMLElement
         * @param event DOM event
         */
        onMouseMove(element: HTMLElement, event: TouchEvent | MouseEvent): void {
            const currentCoordinate = this.getCurrentCoordinate(event);

            if (!this.initialCoordinate && this.initialCoordinate === currentCoordinate) {
                return;
            }

            this.delta = (this.initialCoordinate - currentCoordinate);

            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (!elemCarouselTrack) {
                return;
            }

            elemCarouselTrack.classList.add(this.prefs().grabbingClass);

            if (!this.delta) {
                return;
            }

            if (this.carouselDirection === 'horizontal') {
                elemCarouselTrack.scrollLeft = this.clamp(this.initialScrollPosition + this.delta, 0, elemCarouselTrack.scrollWidth);
            } else {
                elemCarouselTrack.scrollTop = this.clamp(this.initialScrollPosition + this.delta, 0, elemCarouselTrack.scrollHeight);
            }
        }

        /**
         * @param val our number value, this being our scroll location
         * @param min our min possible value, this is typically 0, can't scroll beyond 0
         * @param max our max possile value, this is the scroll width of the element, so we can't go right beyond its limit
         * @returns number
         */
        clamp(val: number, min: number, max: number) {
            // eslint-disable-next-line no-nested-ternary
            return val > max ? max : val < min ? min : val;
        }

        /**
         * @description Mouseup event handler
         * @listens dom#mouseup
         */
        onMouseUp(): void {
            if (this.mouseMoveDisposable) {
                this.mouseMoveDisposable.forEach(disposable => disposable());
                this.mouseMoveDisposable = undefined;
            }

            if (this.mouseLeaveDisposable) {
                this.mouseLeaveDisposable.forEach(disposable => disposable());
                this.mouseLeaveDisposable = undefined;
            }

            // we should remove scroll-snap-type with delay, otherwise it cause bouncing
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack);

            this.grabbingRemoveTimeout = setTimeout(
                () => elemCarouselTrack.removeClass(this.prefs().grabbingClass),
                this.grabbingTimeoutValue
            );

            // elemCarouselTrack.removeClass(this.prefs().grabbingClass);
        }

        /**
         * @description Method to get event coordinate depending on carousel direction
         * @param event event object
         * @returns event coordinate
         */
        getCurrentCoordinate(event: MouseEvent | TouchEvent) {
            let currentCoordinate = 0;

            if (event instanceof MouseEvent) {
                currentCoordinate = this.carouselDirection === 'horizontal'
                    ? event.clientX
                    : event.clientY;
            } else if (event instanceof TouchEvent) {
                currentCoordinate = this.carouselDirection === 'horizontal'
                    ? event.touches[0].pageX
                    : event.touches[0].pageY;
            }

            return currentCoordinate;
        }

        // Pagination
        // =====================================================================

        /**
         * @description Pagination init logic
         */
        initPagination(): void {
            if (this.hasNoScroll()) {
                return;
            }

            this.has('pagination', paginationRefEl => {
                const pagination = paginationRefEl.get();

                if (pagination) {
                    // If empty pagination - we need to render it. Otherwise - create.
                    if (pagination.innerHTML === '') {
                        this.createPaginationElements();
                    } else {
                        this.pagination = Promise.resolve(pagination);
                    }
                }
            });
        }

        /**
         * @description Pagination click handler
         * @listens dom#click
         * @param el source of event
         */
        handlePaginationClick(el: IRefElement): void {
            const pageIndex = el.data('page');

            if (pageIndex !== null) {
                this.scrollToPage(parseInt(pageIndex + '', 10));
            }
        }

        /**
         * @description Carousel page click handler
         * @param el page element
         * @listens dom#click
         * @emits Carousel#pageclicked
         */
        onPageClick(el: IRefElement): void {
            if (this.delta === 0) {
                /**
                 * @description Event to carousel page click
                 * @event Carousel#pageclicked
                 */
                this.emit('pageclicked', el.data('page'));
            }
        }

        /**
         * @description Create Pagination elements for carousel
         */
        createPaginationElements(): void {
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (!elemCarouselTrack) {
                return;
            }

            // We need to use round, not ceil, since it called on scroll,
            // in case of last it would generate falls positive
            const numberOfPages = Math.round(elemCarouselTrack.scrollWidth / elemCarouselTrack.clientWidth);
            const pagination = new Array(numberOfPages).fill(0).map((_el, i) => ({ page: i }));

            this.pagination = new Promise((resolve) => {
                this.render(undefined, { pagination }, this.ref('pagination')).then(() => {
                    // @ts-ignore possibly undefined
                    return resolve(this.ref('pagination').get());
                });
            });
        }

        /**
         * @description Set active pagination
         */
        setActivePagination(): void {
            if (!this.pagination) {
                return;
            }

            this.pagination.then((pagination: HTMLElement) => {
                if (!pagination) {
                    return;
                }

                const currentPageIndex = this.getCurrentPageIndex();
                const currentPageNode = pagination.children[currentPageIndex];

                if (!currentPageNode) {
                    this.initPagination();
                }

                pagination.children[this.currentPage || 0].classList.remove(
                    this.prefs().pagerCurrentClass
                );

                currentPageNode.classList.add(this.prefs().pagerCurrentClass);

                this.currentPage = currentPageIndex;
            });
        }

        /**
         * @description Get current carousel page index
         * @returns Current carousel page index
         */
        getCurrentPageIndex(): number {
            const carouselTrackElem = this.ref(this.prefs().elemCarouselTrack).get();

            if (!(carouselTrackElem instanceof HTMLElement)) {
                return 0;
            }

            const currentPosition = this.carouselDirection === 'horizontal'
                ? carouselTrackElem.scrollLeft : carouselTrackElem.scrollTop;

            const itemDimension = this.carouselDirection === 'horizontal'
                ? carouselTrackElem.children[0].clientWidth : carouselTrackElem.children[0].clientHeight;

            return Math.round((currentPosition || 0) / (itemDimension || 1));
        }

        // Pagination methods related to PDP gallery
        // =====================================================================

        /**
         * @description Adds some defined class on carousel inner elements
         * @param pageIndex - element index
         * @returns carousel instance
         */
        markCurrentPage(pageIndex: number): Carousel {
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            [].slice.call((elemCarouselTrack?.children)).forEach((element: HTMLElement) => {
                const dataPage = parseInt(((element && element.getAttribute('data-page')) || '0'), 10);

                element.classList.toggle(this.prefs().pagerCurrentClass, dataPage === pageIndex);
            });

            return this;
        }

        /**
         * @description Scrolls active element into carousel viewport.
         * This might be useful when using dependent carousels, like on PDP images/thumbnails
         * @returns carousel instance
         */
        scrollIntoView(): Carousel {
            const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

            if (!elemCarouselTrack) {
                return this;
            }

            // @ts-ignore possibly undefined current element
            const currentElement: HTMLElement = [].find.call(elemCarouselTrack.children,
                (element: HTMLElement) => {
                    return element.classList.contains(this.prefs().pagerCurrentClass);
                });

            if (!currentElement) {
                return this;
            }

            if (this.carouselDirection === DEFAULT_DIRECTION) {
                if ((currentElement.offsetLeft + currentElement.clientWidth)
                    > (elemCarouselTrack.scrollLeft + elemCarouselTrack.clientWidth)) {
                    this.scrollTo(0, currentElement.offsetLeft);
                } else if (currentElement.offsetLeft < elemCarouselTrack.scrollLeft) {
                    this.scrollTo(0, currentElement.offsetLeft);
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if ((currentElement.offsetTop + currentElement.clientHeight)
                    > (elemCarouselTrack.scrollTop + elemCarouselTrack.clientHeight)) {
                    this.scrollTo(currentElement.offsetTop, 0);
                } else if (currentElement.offsetTop < elemCarouselTrack.scrollTop) {
                    this.scrollTo(currentElement.offsetTop, 0);
                }
            }

            return this;
        }

        /**
         * @description Programmatically handle scroll to focused carousel item.
         * This is the fix for Chrome bug where it could not calculate item boundaries properly
         * and threat item like it into view. The bug is on PDP Gallery.
         * Applicable only for horizontal scroll.
         */
        handleScrollToFocusedItem(): void {
            if (this.carouselDirection !== 'horizontal') { return; }

            const track = this.ref(this.prefs().elemCarouselTrack).get();
            const activeItem = document.activeElement;

            if (!activeItem || !(activeItem instanceof HTMLElement) || activeItem.parentElement !== track) {
                return;
            }

            const activeItemLeft = activeItem.getBoundingClientRect().left;
            const trackStart = track.getBoundingClientRect().left;
            const trackEnd = trackStart + track.clientWidth;

            if (activeItemLeft >= trackEnd || activeItemLeft <= trackStart) {
                track.scrollTo(activeItem.offsetLeft, 0);
            }
        }

        /**
         * @description Next/Prev Buttons keyboard handler. Used only on PDP gallery
         * we need this method to prevent Zoom opened on Enter
         * @listens dom#keydown
         * @param element dom node that trigger the event
         * @param event  Event object
         */
        handleKeydown(element: IRefElement, event: KeyboardEvent): void {
            if (event.keyCode === keyCode.RETURN || event.keyCode === keyCode.SPACE) {
                event.stopPropagation();
            }
        }
    }

    return Carousel;
}
