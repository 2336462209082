import { timeout } from 'widgets/toolbox/util';
import { PausableTimeout } from 'widgets/toolbox/PausableTimeout';

/**
 * @description Extended GlobalAlerts implementation.
 * @param BaseGlobalAlerts Base widget for extending
 * @returns GlobalAlerts class
 */
export default function (BaseGlobalAlerts: ReturnType<typeof import('widgets/global/GlobalAlerts').default>) {
    /**
     * @class GlobalAlerts
     * @augments Widget
     * @classdesc Global Alerts with accessibility alert role possibility.
     */
    class GlobalAlerts extends BaseGlobalAlerts {
        pausableTimeout: PausableTimeout | undefined

        showTimeout: (() => void) | undefined

        prefs() {
            return {
                showTimeout: 500,
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization, onDestroy hook to clear show timeout.
         */
        init(): void {
            super.init();

            this.onDestroy(() => this.disposeShowTimeout());
        }

        /**
         * @description Dispose show timeout and clear it resources
         */
        disposeShowTimeout(): void {
            if (this.showTimeout) {
                this.showTimeout();
                this.showTimeout = undefined;
            }
        }

        /**
         * @description Show Global Alert by starting pausable timeout
         * @param data - Object with message
         * @param data.accessibilityAlert - Message to be shown and pronounced
         * @param data.alertClassName - Optional CSS class to be applied to alert
         */
        showAlert(data: {accessibilityAlert: string, alertClassName: string}): void {
            if (data && data.accessibilityAlert) {
                if (this.shown) {
                    this.hideAlert();
                }

                this.showTimeout = timeout(() => {
                    this.ref('item').attr('role', 'none');

                    this.ref('item')
                        .setText(data.accessibilityAlert)
                        .show();
                    this.shown = true;

                    this.ref('item').attr('role', 'alert');

                    if (data.alertClassName) {
                        this.ref('item').addClass(data.alertClassName);
                        this.ref('item').data('customClasses', data.alertClassName);
                    }

                    this.pausableTimeout = new PausableTimeout(() => {
                        this.hideAlert();
                    }, this.prefs().timeout);
                }, this.prefs().showTimeout);
            }
        }

        /**
         * @description Hide Global Alert and clear timeout
         */
        hideAlert(): void {
            this.ref('item').attr('role', 'none');

            const customClasses = this.ref('item').data('customClasses') as string;

            if (customClasses) {
                this.ref('item').removeClass(customClasses);
                this.ref('item').data('customClasses', null);
            }

            super.hideAlert();
        }
    }

    return GlobalAlerts;
}
