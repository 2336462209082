/**
 * @description Copies some text to clipboard, using browser's `textarea` tag and `document.execCommand('copy')`
 * @param {string} text text to copy
 * @returns {void}
 */
export function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
        navigator.clipboard.writeText(text);
    } else {
        const el = document.createElement('textarea');

        el.ariaHidden = 'true';
        el.style.position = 'absolute';
        el.style.top = '0';
        el.style.opacity = '0';
        el.value = text || '';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }
}

/**
 * @description Append parameter to provided URL
 * @param {string} initialUrl initial url
 * @param {string} name name of params
 * @param {string} value value of param
 * @param {boolean} force if we need to add param even if it already exists
 * @returns {string} url with appended param
 */
export function appendParamToURL(initialUrl, name, value, force = false) {
    const url = new URL(initialUrl, document.location.origin);

    if (url.searchParams.get(name) && !force) {
        return url.toString();
    }

    url.searchParams.append(name, value);

    return url.toString();
}

/**
 * @description Expose tools for global usage (for ex. in case of AJAX-requests in legacy 3-d party scripts)
 * @param {string} name - Method name
 * @param {function} method - Method to expose
 * @returns {void}
 */
export function exposeMethodGlobally(name, method) {
    if (!window.tools) {
        window.tools = {};
    }

    window.tools[name] = method;
}
