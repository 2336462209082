// @ts-nocheck
/* eslint-disable max-len */
/* eslint-disable indent */
const hasContext = (/** @type {[]} */values) => values.some(v => window.contexts.includes(v));

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext(['account', 'cart']),
    load: () => Promise.all([
        import(/* webpackChunkName: 'rewards.widgets' */ './widgets/account/Rewards').then(d => d.default)
    ]).then(deps => {
        const [
            Rewards
        ] = deps;

        return {
            listId: 'rewards.widgets',
            widgetsDefinition: () => [
                    ['rewards', Rewards]
            ]
        };
    })
});
